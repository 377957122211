@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


@layer base{
  body{
    @apply font-[Raleway]
  }
  li {
    @apply px-10;
    @apply py-4;
  }
 
}


.active{
  background: #FD853A;
  border-radius: 50px;
}




@media only screen and (max-width:768px) {
  .box-shadow{
  background: transparent;
      box-shadow: 6px 6px 14px 0 rgba(0, 0, 0, 0.2),
    -8px -8px 18px 0 rgba(255, 255, 255, 0.55);
}

}


